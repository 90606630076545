<template>
  <div>

  <v-container>
    <v-row style="margin-top: 50px" v-if="carregando">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
  
      
    <v-form v-else v-model="valid" ref="formulario" lazy-validation>
      <v-widget title="Informações do Item" :temCampoObrigatorio="true">
        <div slot="widget-content">

          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="item.codigo"
                name="codigo"
                label="Código *"
                id="codigo"
                autocomplete="off"
                required
                :rules="[rules.obrigatorio]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="9">
              <v-text-field
                v-model="item.descricao"
                name="descricao"
                label="Descrição *"
                id="descricao"
                autocomplete="off"
                counter="200"
                :rules="[rules.obrigatorio, rules.maximo200Caracteres]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
              <as-campo-valor
                v-model="item.valor"
                name="valor"
                label="Valor"
                id="valor"
                prefix="R$"
                :rules="[rules.valorMaiorQueZero]"
              ></as-campo-valor>
            </v-col>

            <v-col cols="12" sm="3">
              <as-campo-valor
                v-model="item.estoque"
                name="estoque"
                label="Estoque"
                id="estoque"
                :decimais="0"
                :rules="[rules.valorMaiorQueZero]"
              ></as-campo-valor>
            </v-col>

            <!-- <v-col cols="12" sm="3">
              <campo-data
                v-model="item.data_cadastro"
                name="data_cadastro"
                label="Data Cadastro"
                id="data_cadastro"
                autocomplete="off"
              ></campo-data>
            </v-col> -->

          </v-row>

          <v-row dense>
            <v-col cols="12" sm="2">
              <buscar-produto v-model="item.produto_codigo" ref="codigoProduto" @produtoSelecionado="produtoSelecionado"></buscar-produto>
            </v-col>
              <v-col cols="12" sm="10">
                <v-text-field label="Descrição" v-model="item.produto_descricao" readonly></v-text-field>
              </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete label="Estado" v-model="item.estado" :items="ESTADOS_AUTOCOMPLETE" @change="sigla => log('Sigla Estado:', sigla)"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="CNPJ/CPF" v-model="item.cnpj_cpf" v-mask="['###.###.###-##', '##.###.###/####-##']"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <campo-cep v-mask="'#####-###'" label="CEP" buscarcep @resultado="endereco => log(endereco)" v-model="item.cep" append-icon="map"></campo-cep>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Telefone" v-model="item.telefone" v-mask="'(##)####-####'"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Celular" v-model="item.celular" v-mask="'(##)#####-####'"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="E-mail *" v-model="item.email"></v-text-field>
            </v-col>
          </v-row>
          

        </div>
        <template v-slot:widget-footer-action>
          <v-btn text @click="cancelar" :disabled="salvando">Cancelar</v-btn>
          <v-btn color="primary" :loading="salvando" @click="salvar" :disabled="!valid">Salvar</v-btn>
        </template>
      </v-widget>
    </v-form>  
  </v-container>
  </div>
</template>


<script>

import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import { mask } from 'vue-the-mask';
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';

export default {
  
  directives: {
    mask
  },

  data: () => ({
    valid: true,
    salvando: false,

    // rules: {
    //   obrigatorio: value => !!value || 'Campo obrigatório',
    //   tamanhoMinimo: value => (value && value.length > 10) || 'Tamanho mínimo 20 caracteres',
    //   zero: value => parseFloat(value) > 0 || 'O valor deve ser maior que zero',
    //   email: value => {
    //     const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //     return pattern.test(value) || 'E-mail inválido.'
    //   },
    // },

    rules: {...regras},

    ESTADOS_AUTOCOMPLETE: constantes.ESTADOS_AUTOCOMPLETE,
  }),

  computed: {
    
    ...mapState('crud', {
      item: 'item',
      carregando: 'carregando'
    }),

  },

  methods: {

    async salvar() {
      if(this.$refs.formulario.validate()) {
        try{

          this.salvando = true;

          if (this.item._id) {
            await this.$store.dispatch('crud/atualizar', this.item);
          } else {
            await this.$store.dispatch('crud/salvar', this.item);
          }

          this.$router.push({ name: 'CrudLista'});
        }
        catch(erro){
          console.log(erro);
          throw erro;
        } finally {
          this.salvando = false;
        }
      }
    },

    cancelar() {
      this.$router.push({ name: 'CrudLista'});
    },

    produtoSelecionado(produto) {
      this.item.produto_codigo = produto.codigo;
      this.item.produto_descricao = produto.descricao;
      this.item.valor = produto.valor;
    },

    log(...data) {
      console.log(...data);
    }
  },

  async created() {
    if (this.$router.currentRoute.name == 'CrudEditar' && !this.item._id) {
      this.$router.push({name: 'CrudLista'});
    }
  }
};


</script>